import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import { Checkbox } from "../../Atoms/Checkbox/Checkbox";

import "./InboxRow.scss";
import {
  IInboxRowLabelProps,
  InboxRowLabel,
} from "./components/InboxRowLabel/InboxRowLabel";
import { InboxRowTags } from "./components/InboxRowTags/InboxRowTags";

export type Content = string | JSX.Element;
const CLOSED_JOB_TEXT = "closed";

export interface IInboxRowProps {
  sender: Content;
  isChecked: boolean;
  value: string;
  title: Content;
  additionalInfo: Content[];
  tags?: Content[];
  labels?: IInboxRowLabelProps[];
  isRead?: boolean;
  url?: string;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  onSelect?(e?: React.MouseEvent<HTMLElement>): void;
}

function renderAdditionalInfo({
  additionalInfo,
  className,
  insideTable,
}: {
  additionalInfo: Content[];
  className?: string;
  insideTable?: boolean;
}) {
  const TableWrapper = ({ children }: { children: React.ReactNode }) => (
    <td className={cx("c-inbox-row__additional-info", className)}>
      {children}
    </td>
  );
  const StandardWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className={cx("c-inbox-row__additional-info", className)}>
      {children}
    </div>
  );
  return additionalInfo?.map((content, index) =>
    insideTable ? (
      <StandardWrapper key={index}>{content}</StandardWrapper>
    ) : (
      <TableWrapper key={index}>
        <span
          className={cx({
            "font-semibold text-red-500":
              typeof content === "string" &&
              content.toLowerCase() === CLOSED_JOB_TEXT,
          })}
        >
          {content}
        </span>
      </TableWrapper>
    )
  );
}

export const InboxRow: React.FC<IInboxRowProps> = ({
  isChecked,
  isRead,
  onClick,
  onSelect,
  sender,
  title,
  url,
  value,
  additionalInfo,
  labels,
  tags,
  className,
}) => {
  const hasTags = Boolean(tags?.length);
  const titleId = `inbox-row-title-${value}`;
  const senderId = `inbox-row-sender-${value}`;

  const renderLabels = () =>
    labels?.length &&
    labels?.map((label) => <InboxRowLabel key={label.text} {...label} />);

  return (
    <a
      href={url ?? "#"}
      onClick={onClick}
      aria-labelledby={`${senderId} ${titleId}`}
      className={cx("c-inbox-row", className, {
        "c-inbox-row--read": isRead,
      })}
      data-testid={dataTestIds.componentLibrary["Organisams.InboxRow.rowLink"]}
    >
      <tr
        className="c-inbox-row__content"
        data-testid={dataTestIds.componentLibrary["Organisams.InboxRow.row"]}
      >
        <td className="g-col-lg-3 g-col-sm-12">
          <div className="c-inbox-row__sender">
            <Checkbox
              name="inboxRow"
              value={value}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                onSelect?.(e);
              }}
              checked={isChecked}
              ariaLabel={`${isChecked ? "Unselect" : "Select"} ${title}`}
            />
            <div
              className="c-inbox-row__sender-info"
              data-testid={
                dataTestIds.componentLibrary["Organisams.InboxRow.senderName"]
              }
            >
              <div className="c-inbox-row__sender-name" id={senderId}>
                {sender}
              </div>
              {labels && labels?.length > 0 && (
                <div className="c-inbox-row__sender-name-label g-hidden-sm">
                  {renderLabels()}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="g-col-lg-5 g-col-sm-12">
          <div className="c-inbox-row__title-container">
            <div className="c-inbox-row__title" id={titleId}>
              {title}
            </div>
            {hasTags && (
              <div className="c-inbox-row__title-tags g-hidden-sm">
                <InboxRowTags tags={tags!} />
              </div>
            )}
          </div>
        </td>
        {renderAdditionalInfo({
          additionalInfo,
          className: "g-col-lg-2 g-col-sm-6 g-hidden-sm",
        })}
        <td className="g-hidden-md g-hidden-lg g-col-sm-12 c-inbox-row__additional-info-container">
          {renderAdditionalInfo({
            additionalInfo,
            insideTable: true,
          })}
        </td>
        <td className="g-col-sm-12 g-hidden-md g-hidden-lg g-hidden-empty">
          {hasTags && <InboxRowTags tags={tags!} />}
        </td>
        <td className="g-col-sm-12 g-hidden-md g-hidden-lg g-hidden-empty">
          <div className="c-inbox-row__sender-name-label g-hidden-empty">
            {renderLabels()}
          </div>
        </td>
      </tr>
    </a>
  );
};
