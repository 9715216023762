import { IModifiers } from "./getNavHeaderLinks";
import { getMediaRequestsSubNav } from "./sharedLinks";

export function getAgentsNav(modifiers: IModifiers) {
  const { isAgencySettingsFlagEnabled, isMediaRequestEnabled } = modifiers;

  return {
    mainNavigationItems: [
      {
        text: "common:nav.links.industryResources",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1134/help.svg",
        linkUrl:
          "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
      },
      {
        text: "common:nav.links.contactsListings",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2228/contacts.svg",
        linkUrl: "/contacts",
      },
      {
        text: "common:nav.links.jobsFeed",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2230/search.svg",
        linkUrl: "/jobs/matching-my-filters",
        subNavigationItems: [
          {
            text: "common:nav.links.jobMatches",
            linkUrl: "/jobs/matching-my-filters",
          },
          {
            text: "common:nav.links.projects",
            linkUrl: "/jobs/all-opportunities",
          },
          {
            text: "common:nav.links.clientAlerts",
            linkUrl: "/jobs/client-alerts",
          },
          ...getMediaRequestsSubNav(isMediaRequestEnabled),
          {
            text: "common:nav.links.messages",
            linkUrl: "/agentweb/feed/messages",
          },
        ],
      },
      {
        text: "common:nav.links.clients",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/clients.svg",
        linkUrl: "/clients",
        subNavigationItems: [
          {
            text: "common:nav.links.clientList",
            linkUrl: "/clients",
          },
          {
            text: "common:nav.links.emailClientCvs",
            linkUrl: "/agentweb/EmailCvs",
          },
          {
            text: "common:nav.links.searchSuggestions",
            linkUrl: "/searchsuggestions",
          },
          {
            text: "common:nav.links.clientPins",
            linkUrl: "/agentweb/Clients/PinList/filtered",
          },
          {
            text: "common:nav.links.youngPerformerApplications",
            linkUrl: "/join/children/manage.asp",
          },
        ],
      },
      {
        text: "common:nav.links.seekingRepresentation",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/3369/experience.svg",
        linkUrl: "/clients/seeking-representation",
      },
      {
        text: "common:nav.links.accountSettings",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/settings.svg",
        linkUrl: "/settings",
        subNavigationItems: generateSubNavigationItems(
          isAgencySettingsFlagEnabled
        ),
      },
      {
        text: "common:nav.links.helpTutorials",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl:
          "https://www.spotlight.com/the-small-print/help-for-agents-using-the-spotlight-link/",
      },
      {
        text: "common:nav.links.performanceCalendar",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/performancecal.svg",
        linkUrl: "https://calendar.spotlight.com/",
      },
    ],
  };
}

function generateSubNavigationItems(isAgencySettingsFlagEnabled?: boolean) {
  const agencyDetailsLink = isAgencySettingsFlagEnabled
    ? {
        text: "common:nav.links.agencyDetails",
        linkUrl: "/clients/agency",
      }
    : {
        text: "common:nav.links.agencyDetails",
        linkUrl: "/agentweb/Account",
      };

  return [
    {
      text: "common:nav.links.agentUserDetails",
      linkUrl: "https://portal.spotlight.com/portal/account/details",
    },
    agencyDetailsLink,
    {
      text: "common:nav.links.clientAccess",
      linkUrl: "/clientaccess",
    },
    {
      text: "common:nav.links.agentTermsConditions",
      linkUrl: "https://www.spotlight.com/the-small-print/terms-conditions/",
    },
  ];
}
