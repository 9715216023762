import React, { FC } from "react";
import cx from "classnames";
import { TFunction, useTranslation } from "i18n";
import { dataTestIds } from "data-testids";
import "./SubNavigationItem.scss";

export interface ISubNavigationItemProps {
  /** Label of the element, will be displayed */
  text: string;
  /** Render specific content */
  renderLabel?: (t: TFunction) => JSX.Element;
  /** Link of the element, where we will be redirected after clicking on it */
  linkUrl: string;
  /** Optional property means that this sub-navigation item is currently selected, span text will be underlined */
  isSelected?: boolean;
  /** Optional CSS class for the topmost div for style overriding purposes */
  className?: string;
  /** Handler to hook on Click event. onClick handler is an optional parameter.
   *  Use it if you would like to handle redirection manually (for example, using Router history.push function).
   *  If onClick handler isn't provided, SubNavigationItem will work using default Anchor behavior.
   */
  handleClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
}

export const SubNavigationItem: FC<ISubNavigationItemProps> = ({
  text,
  renderLabel,
  linkUrl,
  isSelected,
  className,
  handleClick,
}) => {
  const { t } = useTranslation();
  const tText = t(text);

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (handleClick) {
      handleClick(event, tText, linkUrl);
    }
  };

  return (
    <div
      className="c-subnavigation-item"
      data-testid={
        dataTestIds.componentLibrary[
          "Organisms.NavigationHeader.SubNavigationItem.subNavigationLink"
        ]
      }
    >
      <a
        className={cx(
          "c-subnavigation-item__link",
          { selected: isSelected },
          className
        )}
        href={linkUrl}
        target={linkUrl.startsWith("http") ? "_blank" : "_self"}
        onClick={handleItemClick}
      >
        {renderLabel ? renderLabel(t) : <span>{t(tText)}</span>}
      </a>
    </div>
  );
};

export default SubNavigationItem;
