import React from "react";
import { TFunction } from "i18n";
import { Pill } from "../../../../Atoms/Pill/Pill";

export function getMediaRequestsSubNav(isMediaRequestEnabled?: boolean) {
  return isMediaRequestEnabled
    ? [
        {
          text: "common:nav.links.mediaRequests",
          linkUrl: "/jobs/self-tape-requests",
          renderLabel: (t: TFunction) => (
            <span>
              {t("common:nav.links.mediaRequests")}{" "}
              <Pill className="bg-blue-sky-2 ml-1.5" theme="light" size="md">
                {t("common:nav.links.pill.beta")}
              </Pill>
            </span>
          ),
        },
      ]
    : [];
}
